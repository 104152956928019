.code {
  text-align: left;
  font-size: small;
  font-family: monospace;
}

.box {
  border-radius: 20px;
  margin: 10px;
  padding: 10px;
  width: 400px;
}

.boxHeader {
  font-size: 200%;
}

.boxStatus {
  opacity: 0.5;
  padding: 5px 10px;
}

.refreshButton {
  max-width: 50px;
  margin: 10px;
  filter: invert(70%);

  /* background-color: rgb(146, 146, 146);
    border-radius: 100px;
    padding: 5px; */
}

.refreshImg {
  animation: spin infinite 3s linear;
  animation-play-state: paused;
}

.copyImg {
  max-width: 20px;
  margin: 0 10px;
  filter: invert(100%);
  cursor: pointer;
}

.steamImg {
  margin: 0 10px;
  width: 30px;
  cursor: pointer;
}

.dataBox {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px;
}

.unimportantText {
  opacity: 0.7;
}

.dataContainer {
  display: flex;
  flex-direction: column;
  justify-content: right;
  align-items: flex-end;
  white-space: nowrap;
}

.boxPublicIp {
  font-size: 120%;
  font-family: monospace;
}

.loading {
  animation-play-state: running;
  /* animation-fill-mode: forwards; */
  /* background-color: orange; */
}

.serviceContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes floatUp {
  //   0% {
  //     transform: translateY(0);
  //     opacity: 0;
  //   }
  1% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(-150px);
    opacity: 0;
  }
}

.floater {
  position: absolute;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.8);
  padding: 4px 8px;
  border-radius: 3px;
  user-select: none;
}
.floaterGo {
  animation: floatUp 2s linear forwards;
  //   animation-fill-mode: forwards;
}
.refreshButton:hover {
  filter: invert(85%);
}

.refreshButton:active {
  filter: invert(100%);
}

.stopped {
  background-color: rgb(87, 33, 33);
}

.starting {
  background-color: rgb(24, 25, 102);
}

.running {
  background-color: rgb(38, 104, 0);
}

.stopping {
  background-color: rgb(116, 62, 11);
}
